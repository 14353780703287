<template>
  <div class="content-area shuttle-form">
    <hero-bar v-if="! isModal">
      {{ $t('titles.shuttleRegister') }}

      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{'is-loading':isLoading}"
        @click="registerShuttle"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="min-h-600">
        <form ref="form" @submit.prevent="registerShuttle">
          <div class="columns">
            <div class="column">
              <b-message v-if="isModal" title="Transfer Bilgileri">
                <b-field horizontal>
                  <template slot="label">
                    <b-tag
                      v-if="selectedShuttle"
                      type="is-dark"
                      class="small-tag is-pulled-left pointer mr-5"
                      @click.native="copyTransferAddress('start')"
                    >
                      {{ $t('copy') }}
                    </b-tag>

                    {{ $t('start') }}
                  </template>
                  {{ transferDetails.start_address }}
                </b-field>

                <b-field horizontal>
                  <template slot="label">
                    <b-tag
                      v-if="selectedShuttle"
                      type="is-dark"
                      class="small-tag is-pulled-left pointer mr-5"
                      @click.native="copyTransferAddress('target')"
                    >
                      {{ $t('copy') }}
                    </b-tag>

                    {{ $t('target') }}
                  </template>
                  {{ transferDetails.target_address }}
                </b-field>

                <b-field :label="$t('tranferDate')" horizontal>
                  {{ transferDetails.transfer_date | formatDate }}
                </b-field>
              </b-message>

              <b-field :label="$t('route')" horizontal>
                <b-autocomplete
                  v-model="shadowShuttleName"
                  :placeholder="`* ${$t('choose')} *`"
                  field="name"
                  :keep-first="true"
                  :open-on-focus="true"
                  :data="filteredShuttles"
                  @select="selectShuttle"
                >
                  <template slot-scope="props">
                    <span>
                      {{ props.option.name }}
                      <span class="emptyText">({{ $t(props.option.type) }} - {{ props.option.time }})</span>
                    </span>
                  </template>
                </b-autocomplete>
                <!-- <b-select
                  v-model="form.shuttleId"
                  placeholder="* Seçiniz *"
                  required
                  expanded
                >
                  <option
                    v-for="(shuttle, index) in shuttles"
                    :key="index"
                    :value="shuttle.id"
                  >
                    {{ `${shuttle.name} - ${$t(shuttle.type)} - ${shuttle.time}` }}
                  </option>
                </b-select> -->
              </b-field>

              <b-field :label="$t('departureAddress')" horizontal>
                <gmap-places
                  :place.sync="startLocation"
                  :adress="(startLocation.formatted_address) ? startLocation.formatted_address : ''"
                  :disabled="startDisable"
                  :placeholder="`${$t('chooseLocation')}...`"
                />
              </b-field>

              <b-field :label="$t('arrivalAddress')" horizontal>
                <gmap-places
                  :place.sync="targetLocation"
                  :adress="(targetLocation.formatted_address) ? targetLocation.formatted_address : ''"
                  :disabled="targetDisable"
                  :placeholder="`${$t('chooseLocation')}...`"
                />
              </b-field>

              <b-field :label="$t('aciklama')" horizontal>
                <b-input
                  v-model="form.comment"
                  :placeholder="`${$t('aciklama')}...`"
                  class="is-fullwidth"
                />
              </b-field>


              <div v-if="isModal" class="buttons">
                <b-button
                  type="is-purple"
                  icon-left="content-save"
                  size="is-medium"
                  :class="{'is-loading':isLoading}"
                  expanded
                  @click="registerShuttle"
                >
                  {{ $t('save') }}
                </b-button>
              </div>

              <b-message v-if="selectedShuttle" type="is-info" class="mt-25">
                <b-field v-if="selectedShuttle.description" :label="$t('description')" horizontal>
                  {{ selectedShuttle.description }}
                </b-field>

                <b-field :label="$t('type')" horizontal>
                  {{ $t(selectedShuttle.type) }}
                </b-field>

                <b-field :label="$t('shuttleTime')" horizontal>
                  {{ selectedShuttle.time }}
                </b-field>
              </b-message>
            </div>

            <div v-if="! isModal" class="column">
              <div class="columns is-centered">
                <b-field
                  custom-class="is-large has-text-centered"
                  :label="dateSelected"
                >
                  <b-datepicker
                    v-model="form.dates"

                    :first-day-of-week="1"
                    :month-names="$t('dateTimePicker.monthNames')"
                    :day-names="$t('dateTimePicker.dayNames')"
                    :min-date="minDate"
                    :max-date="maxDate"

                    size="is-large"
                    inline
                    multiple
                  />
                </b-field>
              </div>
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ShuttleRegister',
  title: ({ $i18n }) => $i18n.t('titles.shuttleRegister'),
  components: {
    HeroBar: () => import('../components/HeroBar'),
    CardComponent: () => import('../components/CardComponent'),
    GmapPlaces: () => import('../components/GMapPlacesInput'),
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    transferDetails: {
      type: Object,
      default: () => {}
    },
    transferId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      shuttles: [],
      shadowShuttleName: '',
      startDisable: false,
      targetDisable: false,
      startLocation: {},
      targetLocation: {},
      lastSelectedShuttle: {},
      form: {
        shuttleId: null,
        dates: [],
        comment: ''
      }
    }
  },
  computed: {
    filteredShuttles () {
      return this.shuttles.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(this.shadowShuttleName.toLowerCase()) >= 0
      })
    },
    selectedShuttle () {
      if (!this.form.shuttleId) {
        return false;
      }
      return this.$_.find(this.shuttles, (s) => {
        return s.id == this.form.shuttleId
      });
    },
    minDate () {
      return moment().subtract(1, 'days').toDate()
    },
    maxDate () {
      if (this.selectedShuttle) {
          return moment(this.selectedShuttle.end_date).toDate();
      }

      return moment().add(90, 'days').toDate();
    },
    dateSelected () {
      const selectedCount = this.form.dates.length
      let txtResult = `${this.$t('dates')}: `

      if (selectedCount != 0) {
        txtResult += `(${this.$tc('daySelected', selectedCount, { count: selectedCount })})`
      }

      return txtResult
    }
  },
  watch: {
    selectedShuttle (n) {
      if (!n) {
        return;
      }

      if (n.type == 'exit') {
        this.startDisable = true
        this.targetDisable = false

        if (this.lastSelectedShuttle && this.lastSelectedShuttle.type == 'enter') {
          this.targetLocation = {}
        }

        this.startLocation = {
          formatted_address: n.start_address,
          latlng: n.start_location,
        }

      } else {
        this.targetDisable = true
        this.startDisable = false

        if (this.lastSelectedShuttle && this.lastSelectedShuttle.type == 'exit') {
          this.startLocation = {}
        }

        this.targetLocation = {
          formatted_address: n.start_address,
          latlng: n.start_location,
        }
      }

      if (n) {
        this.lastSelectedShuttle = n;
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  mounted () {
    this.$store.commit('setAsideActiveMenuKey', 'shuttle_register_list')

    if (!this.isModal) {
      this.showSpinner()
    }

    this.getShuttlePlans();
  },
  methods: {
    getShuttlePlans () {
      this.$http
      .get('shuttle-list?autocomplate=1')
      .then(({ data }) => {
        this.shuttles = data.data
      })
      .finally(() => {
        this.hideSpinner()
      })
    },
    selectShuttle (option) {
      if (option) {
        this.form.shuttleId = option.id
      } else {
        this.form.shuttleId = null;
      }
    },
    registerShuttle () {
      if (!this.checkForm()) return false;

      const rightAddresInput = this.selectedShuttle.type == 'enter' ? this.startLocation : this.targetLocation;
      let address = rightAddresInput.formatted_address;

      let dates;

      if (this.isModal) {
        dates = [
          moment(this.transferDetails.transfer_date).format('YYYY-MM-DD').toString()
        ]
      } else {
        dates = this.form.dates.map((date) => {
          return moment(date).format('YYYY-MM-DD').toString();
        });
      }

      let params = {
        shuttle_id: this.form.shuttleId,
        comment: this.form.comment,
        address: address,
        location: rightAddresInput.latlng,
        dates: dates
      }

      if (this.isModal) {
        params.user_id = this.transferDetails.user_id
      }

      this.isLoading = true;

      this.$http
        .post('shuttle-register', params)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Servis Kaydınız Başarıyla Oluşturuldu.',
            type: 'is-success',
            queue: false
          });

          // Move Transfer to Shuttle Request
          if (this.isModal) {
            this.$i18n.locale = this.transferDetails.user.data.attributes.lang;
            const rejectComment = this.$t('transferMoveShuttle', [this.selectedShuttle.name, this.selectedShuttle.time])
            this.$i18n.locale = window.localStorage.getItem('lang')

            this.$http.post('reject-request', {
              id: this.transferId,
              comment: rejectComment,
            }).then(() => {
              this.$store.commit('boardRefresh', true)
              this.$parent.$parent.goBack();
            }).then(() => {
              this.isLoading = false;
            })

          } else {
            this.$router.push({ name:'shuttle_register_list' });
          }
        })
        .catch((e) => {
          this.isLoading = false

          if (e.response.status === 409) {
            const dates = e.response.data.dates.join(' - ')
            const message = e.response.data.message

            this.$buefy.toast.open({
              message: `${dates} <br>${message}`,
              type: 'is-warning',
              duration: 10000,
            })

            return
          }

          if (e.response.status === 403) {
            this.$buefy.toast.open({
              message: e.response.data.error.message,
              type: 'is-warning',
              duration: 10000,
            })

            return
          }

          const errors = [].concat(...Object.values(e.response.data.errors))

          this.$buefy.toast.open({
            message: this.$t('error') + ':<br>' + errors,
            type: 'is-warning',
            duration: 5000,
          })
        });
    },
    checkForm () {
      let bnlFormCheck = true;

      if (
        this.$_.isUndefined(this.startLocation.latlng) ||
        this.$_.isUndefined(this.targetLocation.latlng) ||
        !this.$refs.form.checkValidity()
      ) bnlFormCheck = false;

      if (!bnlFormCheck) {
        this.$refs.form.reportValidity();

        this.$buefy.toast.open({
          message: this.$t('checkFormValidation'),
          type: 'is-warning',
          duration: 5000
        });
        return false;
      }

       if (this.form.dates.length == 0 && !this.isModal) {
        this.$buefy.toast.open({
          message: 'En az bir tarih seçmelisiniz.',
          type: 'is-info',
          duration: 2000
        });

        return false;
      }

      return true;
    },
    copyTransferAddress (way) {
      let address;
      let location;

      if (way == 'start') {
        address = this.transferDetails.start_address
        location = this.transferDetails.start_location
      } else {
        address = this.transferDetails.target_address
        location = this.transferDetails.target_location
      }

      if (this.selectedShuttle.type == 'enter') {
        this.startLocation = {
          formatted_address: address,
          latlng: location,
        }
      } else {
        this.targetLocation = {
          formatted_address: address,
          latlng: location,
        }
      }
    }
  }
}
</script>

<style>
  .shuttle-form .dropdown-item {
    outline: none;
  }
</style>
